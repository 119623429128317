//aboutus page
<template>
<secondary-page-layout :title="$t('aboutUs')" :displayedNavigation="true">
  <div class="about-us">
    <img src="/img/office.png" class="img" alt="office">
    <div class="about-content">
      <p class="primary-text">
        <span class="success-text d-inline-block">{{$t('halykTextBold')}}</span> {{$t('halykText')}}
      </p>
      <a href="https://www.halyksk.kz/ru" class="success-text mb-3">www.halyksk.kz</a>
      <template v-if="getCityById">
        <div class="primary-block city-picker" @click="openModal">
          <icon icon-name="location-icon"/>
          <p class="mb-0 mx-3">{{ getCityById.title }}</p>
          <icon icon-name="accordion-arrow-icon" class="arrow"/>
        </div>
        <p class="secondary-text">{{ getCityById.branches.length }} {{declOfNum(getCityById.branches.length, $t('branches')) }}</p>
        <div class="primary-block branch" v-for="branch in getCityById.branches" :key="branch.id">
          <h2>{{ branch.type }}</h2>
          <div>
            <span class="secondary-text">{{$t('address')}}</span>
            <p class="semibold-text">{{ branch.title }}</p>
            <p class="primary-text fw-light">{{ branch.address }}</p>
          </div>
          <div>
            <span class="secondary-text mb-2">{{$t('phones')}}</span>
            <p class="fw-light">{{ branch.phones }}</p>
          </div>
          <div>
            <span class="secondary-text mb-2">{{$t('schedule')}}</span>
            <p class="fw-light">{{ branch.work_schedule }}</p>
          </div>
          <a href="https://halyksk.kz/ru/branches_atm" class="border-btn">{{$t('showOnMap')}}</a>
        </div>
      </template>
    </div>
  </div>
</secondary-page-layout>
</template>

<script>
import CitiesPickerModal from "../components/Modals/CitiesPickerModal";
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";
export default {
  name: "AboutUs",
  data() {
    return {
      cities: [],
      selectedRegion: null,
    }
  },
  mounted() {
    this.getCities();
  },
  computed: {
    ...mapGetters(['selectedAboutUsCity']),
    getCityById() {
      if(this.cities.length === 0) {
        return null;
      }
      return this.cities.find((city)=>city.id === this.selectedAboutUsCity);
    }
  },
  methods: {
    async getCities() {
      const {data} = await window.axios.get('regions');
      this.cities = data;
    },
    openModal() {
      this.$modal.show(CitiesPickerModal, {},this.$modalConfig)
    },
    declOfNum(number, words) {
      return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";
.about-us {
  .img {
    width: 100%;
    min-height: 180px;
    height: auto;
    max-height: 320px;
    object-fit: cover;
    margin-bottom: 16px;
  }
  .about-content {
    padding: 0 16px;
    .primary-text {
      font-size: 14px;
      margin-bottom: 16px;
    }
    .success-text {
      font-weight: 600;
    }
    .city-picker {
      width: fit-content;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .arrow {
        transform: rotate(180deg);
      }
    }
    .branch {
      .semibold-text {
        font-weight: 600;
      }
      .border-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        border: 1px solid $primary-color;
        padding: 14px 16px;
        border-radius: 12px;
        margin: 0 auto;
        color: $primary-color;
        margin-top: 16px;
      }
    }
  }
}
</style>
